<template>
  <v-btn-group ref="multiActionBtn" class="multi-action-btn" density="compact">
    <qtm-btn v-if="primaryAction" v-bind="primaryAction" secondary>
      {{ primaryAction.label }}
    </qtm-btn>
    <v-menu v-if="secondaryActions.length" content-class="qtm-border" location="bottom end" :width="width">
      <template v-slot:activator="{ props: menuProps }">
        <qtm-icon-btn v-bind="menuProps" color="primary" icon="mdi-chevron-down" variant="outlined" />
      </template>
      <v-list>
        <v-list-item v-for="action in secondaryActions" v-bind="action" :key="action.label">
          {{ action.label }}
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn-group>
</template>

<script setup lang="ts">
import type { VBtnGroup } from 'vuetify/components'

export interface Props {
  actions?: {
    label: string
    onClick: () => void
  }[],
  freeWidth?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  actions: () => [],
})

const primaryAction = computed(() => props.actions[0])
const secondaryActions = computed(() => props.actions.slice(1))

const multiActionBtn = ref<VBtnGroup | null>(null)
const width = ref()

onMounted(() => {
  if (!props.freeWidth) {
    width.value = multiActionBtn.value?.$el.clientWidth
  }
})
</script>

<style lang="scss" scoped>
.multi-action-btn {
  border-color: rgba(var(--v-theme-primary)), 1.0;
}
</style>
